import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';
import {ActionButtons} from "_components/ActionButtons/ActionButtons";
import {PageCopy} from "_components/PageCopy/PageCopy";
import {PromocracyLogo} from "_components/PromocracyLogo/PromocracyLogo";
import PageFlow from "_pages/__routes/PageFlow";
import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';
import {PageBackgroundImages} from "_components/PageBackgroundImages/PageBackgroundImages";
import {Die} from "./Die";

import _IWGameResult from "_pages/InstantWin/_Results/_IWGameResult/_IWGameResult";

import { executeAction } from "__globals/actions";

import {
    empty, getRandomUrlAppendage,
    logIt
} from '__globals/global-functions';
import {
    CONFIG_PAGE_IW_POKER_DICE,
    CLAIM_BEAT_HAND, CLAIM_HAND
} from '__globals/config-reader';
import { addCss, addCSSClass, removeCSSClass } from '__globals/css-functions';
import * as GC from "__globals/GLOBAL-CONSTANTS";
import {sendAction} from "../../../_features/external-integration/external-integration-slice";


let addOn = getRandomUrlAppendage("rd");

const SHOW_FACE_STYLES = {
    "SHOW_1":"show-front",
    "SHOW_2":"show-right",
    "SHOW_3":"show-back",
    "SHOW_4":"show-left",
    "SHOW_5":"show-top",
    "SHOW_6":"show-bottom"
}

export const IWGamePokerDice = props => {

    logIt(GC.PAGE_IW_POKER_DICE + " PAGE IS CALLED", null, false);
    const PAGE_DIV_NAME = "iw_game_poker_dice"; // use underscores
    const PAGE_KEY = "iwGamePokerDice"; // use camel case
    const PAGE_IMAGE_KEY = "iw-game-poker-dice"; // use dashes
    const PAGE_JSON_KEY = GC.PAGE_IW_POKER_DICE; // use init caps (IWGamePokerDice)

    const dispatch = useDispatch();

    const promotion = useSelector(state => state.promotion);
    //logIt( "PROMOTION at " + GC.PAGE_IW_POKER_DICE, promotion, true );

    let entry = useSelector(state => state.entry);
    //logIt( "ENTRY at " + GC.PAGE_IW_POKER_DICE, entry, true);

    let gameClaim = useSelector(state => state.entry.gameClaim);
    //logIt( "GAME CLAIM at " + GC.PAGE_IW_POKER_DICE, gameClaim, true);

    // LOCAL VARIABLES
    const [action, setAction] = useState('');
    const [value, setValue] = useState('');
    const [goNext, setGoNext] = useState(false);

    const [rollCount, setRollCount] = useState( 0 );
    const [resultImage, setResultImage] = useState( '' );
    const [resultCSS, setResultCSS] = useState( '' );
    const [useBeatHand, setUseBeatHand] = useState( '' );

    const [animationGo, setAnimationGo] = useState( false );

    const [hand, setHand] = useState(
        {
            "die1": { "dieNo":"1", "faceNo": "1" },
            "die2": { "dieNo":"1", "faceNo": "1" },
            "die3": { "dieNo":"1", "faceNo": "1" },
            "die4": { "dieNo":"1", "faceNo": "1" },
            "die5": { "dieNo":"1", "faceNo": "1" },
            "die6": { "dieNo":"1", "faceNo": "1" },
            "die7": { "dieNo":"1", "faceNo": "1" },
            "die8": { "dieNo":"1", "faceNo": "1" },
            "die9": { "dieNo":"1", "faceNo": "1" }
        }
    );
    const [beatHand, setBeatHand] = useState(
        {
            "beatDie1": { "dieNo":"1", "faceNo": "1" },
            "beatDie2": { "dieNo":"1", "faceNo": "1" },
            "beatDie3": { "dieNo":"1", "faceNo": "1" },
            "beatDie4": { "dieNo":"1", "faceNo": "1" },
            "beatDie5": { "dieNo":"1", "faceNo": "1" }
        }
    );

    const showResults = () => {
        setTimeout(function() {
            addCSSClass( "game_results_screen", "result-screen-fade-in" );
            addCSSClass( "game_results_options", "result-options-fade-in" );
            addCSSClass( "game_dice_board", "result-poker-dice-game" );
            addCSSClass( PAGE_DIV_NAME + "_page", "result-game-done" );
            setAnimationGo( true );
            //setChooserOn( true );
            if ( gameClaim?.parameters?.result === GC.RESULT_STANDARD ) {
                setRollCount(-2);
            }
        }, promotion.configuration.config.games.pokerdice.resultDelay);
    }

    const showForm = () => {
        addCSSClass( "redeem_form", "result-redeem-fade-in" );
        addCSSClass( "redeem_button", "btn-redeem-out" );
        addCSSClass( "form_win_screen_show_results_button", "btn-redeem-out" );
    }

    const handleAction = ( action, value ) => {
        if ( action === "THANKYOU" ) {
            setRollCount( -1 );
        }
        dispatch( sendAction(promotion, action, value) );
    }

    const rollDie = (dieNo, faceStyle) => {
        let sd = "spin_die_0" + dieNo;
        addCSSClass( sd, SHOW_FACE_STYLES["SHOW_"+faceStyle] );
        let rc = rollCount;
        rc++;
        setRollCount( rc );
    }

    const doAction = (a, v) => {
        if (a.toUpperCase().trim() === GC.ACTION_NAVIGATE) {
            setAction(a);
            setValue(v);
            setGoNext(true);
        } else {
            dispatch(executeAction(a, v));
        }
    }

    useEffect(() => {
        if ( !empty(promotion) ) {
            // set use beat hand here

        }
    }, [promotion] );

    useEffect(() => {
        if ( !empty(promotion) && !empty(gameClaim) ) {
            setUseBeatHand( CONFIG_PAGE_IW_POKER_DICE( promotion ).useBeatHand );
            setBeatHand( CLAIM_BEAT_HAND(gameClaim) );
            setHand( CLAIM_HAND(gameClaim) );
            setResultCSS(gameClaim.parameters.resultCss);
            //setResultImage( gameClaim.parameters.resultImage );
        }
    }, [gameClaim] );

    useEffect(() => {
        if ( (promotion?.retrieved) && !empty(resultCSS) ) {
            addCss( promotion, resultCSS );
        }
    }, [resultCSS]);

    useEffect(() => {
        if ( rollCount >= 9 ) {
            showResults();
        } else if ( rollCount === -1  ) {
            setGoNext( true );
        }
    }, [rollCount] );

    if (goNext) {

        return (<PageFlow page={GC.PAGE_IW_POKER_DICE} error={null} action={action} value={value}/>);

    } else {

        return (
            <React.Fragment>
                <TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title}/>
                <div id={PAGE_DIV_NAME + "_page"}>

                    <table>
                        <tbody>
                        <tr>
                            <td className="page-visual">

                                <PageBackgroundImages
                                    promotion={promotion}
                                    pageKey={PAGE_IMAGE_KEY}
                                />

                            </td>
                            <td className="page-content">

                                <div id={PAGE_DIV_NAME + "_content"}>

                                    <PageCopy
                                        promotion={promotion}
                                        configKey={PAGE_KEY}
                                        jsonKey={PAGE_JSON_KEY}
                                    />

                                </div>

                                    <div id='game_dice_board'>

                                        <div id='beat_dice'>
                                            <div id="roll_instructions">Roll the dice and see if you beat this hand: </div>
                                            <Die promotion={promotion} beatDieNo="1" dieNo={beatHand.beatDie1.dieNo} rollDie={rollDie} addOn={addOn} dieType="beat" dieFaceNo={beatHand.beatDie1.faceNo} />
                                            <Die promotion={promotion} beatDieNo="2" dieNo={beatHand.beatDie2.dieNo} rollDie={rollDie} addOn={addOn} dieType="beat" dieFaceNo={beatHand.beatDie2.faceNo} />
                                            <Die promotion={promotion} beatDieNo="3" dieNo={beatHand.beatDie3.dieNo} rollDie={rollDie} addOn={addOn} dieType="beat" dieFaceNo={beatHand.beatDie3.faceNo} />
                                            <Die promotion={promotion} beatDieNo="4" dieNo={beatHand.beatDie4.dieNo} rollDie={rollDie} addOn={addOn} dieType="beat" dieFaceNo={beatHand.beatDie4.faceNo} />
                                            <Die promotion={promotion} beatDieNo="5" dieNo={beatHand.beatDie5.dieNo} rollDie={rollDie} addOn={addOn} dieType="beat" dieFaceNo={beatHand.beatDie5.faceNo} />
                                        </div>

                                        <div id='game_dice'>

                                            <Die promotion={promotion} dieNo="1" rollDie={rollDie} addOn={addOn} dieType="spin" dieFaceNo={hand.die1.faceNo}/>
                                            <Die promotion={promotion} dieNo="2" rollDie={rollDie} addOn={addOn} dieType="spin" dieFaceNo="3"/>
                                            <Die promotion={promotion} dieNo="3" rollDie={rollDie} addOn={addOn} dieType="spin" dieFaceNo="4"/>
                                            <Die promotion={promotion} dieNo="4" rollDie={rollDie} addOn={addOn} dieType="spin" dieFaceNo="2"/>
                                            <Die promotion={promotion} dieNo="5" rollDie={rollDie} addOn={addOn} dieType="spin" dieFaceNo="4"/>
                                            <Die promotion={promotion} dieNo="6" rollDie={rollDie} addOn={addOn} dieType="spin" dieFaceNo="6"/>
                                            <Die promotion={promotion} dieNo="7" rollDie={rollDie} addOn={addOn} dieType="spin" dieFaceNo="6"/>
                                            <Die promotion={promotion} dieNo="8" rollDie={rollDie} addOn={addOn} dieType="spin" dieFaceNo="5"/>
                                            <Die promotion={promotion} dieNo="9" rollDie={rollDie} addOn={addOn} dieType="spin" dieFaceNo="4"/>

                                        </div>

                                    </div>

                                    {/** Component holding all the game results **/}
                                    <_IWGameResult promotion={promotion}
                                                   gameClaim={gameClaim}
                                                   entry={entry}
                                                   handleAction={handleAction}
                                                   showForm={showForm}
                                                   animationGo={animationGo}/>

                                    <ActionButtons
                                        promotion={promotion}
                                        configKey={PAGE_KEY}
                                        jsonKey={PAGE_JSON_KEY}
                                        doAction={doAction}
                                    />

                                <div id="game_results_screen">
                                    <div className="view-desktop">
                                        <img
                                            src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
                                    </div>
                                    <div className="view-phone" style={{display: 'none'}}>
                                        <img
                                            src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/${resultImage}`}/>
                                    </div>
                                </div>



                                <PromocracyLogo promotion={promotion}/>

                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <CustomContent/>

                </div>

            </React.Fragment>

        );

    }

}