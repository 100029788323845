import axios from 'axios'
import { getRandomUrlAppendage, logIt } from '__globals/global-functions';

const hostName = window.REACT_APP_PROMOTION_DOMAIN;
const gameHostName = window.REACT_APP_GAME_DOMAIN;
const prefix = "https://";

let addOn = getRandomUrlAppendage("rd");

export async function postRemoteEntry(entry) {
    logIt("POST REMOTE ENTRY", entry, true);
    const url = prefix + hostName + '/submit-entry';
    const { data } = await axios.post(url, entry, { withCredentials: true });
    return data;
}

export async function getCaptchaAuthToken( promoId, captchaData ) {
    logIt("POST CAPTCHA AUTH", captchaData, true);
    const url = prefix + hostName + '/auth-token/' + promoId;
    const { data } = await axios.post( url, captchaData, { withCredentials: true } );
    return data;
}

export async function getEntryCount( tokenData ) {
    logIt("ENTRY COUNT TOKEN DATA", tokenData, false);
    const url = prefix + hostName + '/entry-count';
    const { data } = await axios.post( url, tokenData, { withCredentials: true } );
    return data;
}

export async function getEntry() {
    const url = prefix + hostName + '/entry';
    const { data } = await axios.get(url);
    return data;
}

export async function getPromotion(domain) {
    const url = prefix + hostName + '/promotion/config?domain=' + domain;
    //logIt( "url AT getPromotion", url, false );
    const { data } = await axios.get(url);
    return data;
}

export async function getEntryGroups(promoId, token) {
    const url = prefix + hostName + '/entry-groups/' + promoId;
    //logIt( "URL at getEntryGroups", url, false );
    //config.headers.Authorization = `Bearer ${token}`;
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        responseType: 'application/json'
    }
    const { data } = await axios.get(url, config);
    return data;
}

export async function getToken(promoId, testPassword) {
    // DO ALL THIS AT THE BEGINNING AND STORE IN STATE

    let url = prefix + hostName + '/entry-token/' + promoId;
    if (testPassword !== null && testPassword !== undefined && testPassword !== "") {
        url += '?test=' + testPassword
    }
    //logIt( "getToken URL", url, false );
    const { data } = await axios.get(url, { withCredentials: true });
    return data;
}

export async function getPromoJSON(promoName, promoLanguage) {
    let useName = promoName;
    let url = `https://readysetpromo.s3-us-west-2.amazonaws.com/${useName}/json/promo.json${addOn}`;
    if (promoLanguage !== "en") {
        url = `https://readysetpromo.s3-us-west-2.amazonaws.com/${useName}/json/${promoLanguage}/promo.json${addOn}`;
    }
    const config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
        },
        responseType: 'application/json'
    }
    const { data } = await axios.get(url, config);
    return data;
}

export async function getPromoStatus(promoId, promoLanguage) {
    let url = prefix + hostName + '/entry-group/verify/' + promoId;
    // if (testPassword !== null && testPassword !== undefined && testPassword !== "") {
    //     url += '?test=' + testPassword
    // }
    //logIt( "getToken URL", url, false );
    const { data } = await axios.get(url, { withCredentials: true });
    return data;

}

export async function getTriviaJSON(promoName, promoLanguage) {
    let useName = promoName;
    let url = `https://readysetpromo.s3-us-west-2.amazonaws.com/${useName}/json/trivia.json${addOn}`;
    if (promoLanguage !== "en") {
        url = `https://readysetpromo.s3-us-west-2.amazonaws.com/${useName}/json/${promoLanguage}/trivia.json${addOn}`;
    }
    const config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
        },
        responseType: 'application/json'
    }
    const { data } = await axios.get(url, config);
    return data;
}

export async function getPromocracyMapJSON(promoName, promoLanguage) {
    let useName = promoName;
    let url = `https://readysetpromo.s3-us-west-2.amazonaws.com/${useName}/json/promocracy_map_new.json${addOn}`;
    if (promoLanguage !== "en") {
        url = `https://readysetpromo.s3-us-west-2.amazonaws.com/${useName}/json/${promoLanguage}/promocracy-map.json${addOn}`;
    }
    const config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
        },
        responseType: 'application/json'
    }
    try {
        const {data} = await axios.get(url, config);
        return data;
    } catch( error ) {
        logIt( "ERROR GETTING MAP", error, true);
        return null;
    }

}

export async function getGameClaim(game, token, testResult, ambassador) {
    let url;
    //logIt( "AMBASSADOR at getGameClaim", ambassador, true );
    if (ambassador !== null && ambassador !== undefined && ambassador !== "" && ambassador.school != undefined && ambassador.school !== null && ambassador.school !== '') {
        url = prefix + gameHostName + '/game-claim?game=' + game + ambassador.school + '&token=' + token;
    } else {
        url = prefix + gameHostName + '/game-claim?game=' + game + '&token=' + token;
    }
    if (testResult !== null && testResult !== undefined && testResult !== "") {
        url += '&testResult=' + testResult
    }
    //logIt( "getGameClaim URL", url, false );
    const { data } = await axios.get(url, { withCredentials: true });
    return data;
}

export async function commitGameClaim(game, token) {  // TODO: DOES THIS NEED THE TEST RESULT

    let url;
    url = prefix + gameHostName + '/game-claim-commit?game=' + game + '&token=' + token;
    logIt("Committing the game claim", url, false);
    const { data } = await axios.get(url, { withCredentials: true });

    return data;

}
