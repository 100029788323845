import {
    empty,
    logIt
} from '__globals/global-functions';

/** ***************** **/
/** PROMOTION CONFIGS **/
/** ***************** **/
export function CHECK_PROMO( promotion ) {
    if ( empty(promotion) ) {
        logIt( "ERROR: NO PROMOTION" );
        return false;
    }
    return true;
}
export function CONFIGURATION( promotion ) {
    if ( !CHECK_PROMO(promotion) ) { return undefined; }
    let config = promotion.configuration;
    return config;
}
export function CONFIG( promotion ) {
    if ( !CHECK_PROMO(promotion) ) { return undefined; }
    let config = promotion.configuration.config;
    return config;
}
export function PROMO_TYPE( promotion ) {
    if ( !CHECK_PROMO(promotion) ) { return undefined; }
    let config = CONFIGURATION(promotion).type.value;
    return config;
}

/** VARIATIONS **/
export function VARIATIONS( promotion ) {
    if ( !CHECK_PROMO(promotion) ) { return undefined; }
    let config = CONFIG(promotion).variations;
    return config;
}
export function PROMOCRACY( promotion ) {
    if ( !CHECK_PROMO(promotion) ) { return undefined; }
    let config = VARIATIONS( promotion );
    return config;
}

/** PAGES **/
export function PAGES( promotion ) {
    if ( !CHECK_PROMO(promotion) ) { return undefined; }
    let config = CONFIG(promotion).pages;
    return config;
}

/** ****************** **/
/** GAME CLAIM CONFIGS **/
/** ****************** **/
export function CHECK_CLAIM( gameClaim ) {
    if ( empty(gameClaim) ) {
        logIt( "ERROR: NO GAME CLAIM" );
        return false;
    }
    return true;
}
export function PARAMETERS( gameClaim ) {
    if ( !CHECK_CLAIM(gameClaim) ) { return undefined; }
    let config = gameClaim.parameters;
    return config;
}

/** POKER DICE GAME **/
// game claim
export function RESULT_HANDS( gameClaim ) {
    if ( !CHECK_CLAIM(gameClaim) ) { return undefined; }
    let config = PARAMETERS(gameClaim).resultHands;
    return config;
}
export function CLAIM_BEAT_HAND( gameClaim ) {
    if ( !CHECK_CLAIM(gameClaim) ) { return undefined; }
    let config = RESULT_HANDS(gameClaim).beatHand;
    return config;
}
export function CLAIM_HAND( gameClaim ) {
    if ( !CHECK_CLAIM(gameClaim) ) { return undefined; }
    let config = RESULT_HANDS(gameClaim).hand;
    return config;
}

// promo config
export function CONFIG_PAGE_IW_POKER_DICE( promotion ) {
    if ( !CHECK_CLAIM(promotion) ) { return undefined; }
    let config = PAGES(promotion).iwGamePokerDice;
    return config;
}

