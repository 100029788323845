import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';
import {ActionButtons} from "_components/ActionButtons/ActionButtons";
import {PageCopy} from "_components/PageCopy/PageCopy";
import {PromocracyLogo} from "_components/PromocracyLogo/PromocracyLogo";
import PageFlow from "_pages/__routes/PageFlow";
import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';
import {PageBackgroundImages} from "_components/PageBackgroundImages/PageBackgroundImages";

import { executeAction } from "__globals/actions";

import {
    empty,
    logIt, parseErrorMessage
} from '__globals/global-functions';
import * as GC from "__globals/GLOBAL-CONSTANTS";
import {PAGE_ENTRY_CAP, STATUS_ERROR_MESSAGE_LIMIT_REACHED} from "__globals/GLOBAL-CONSTANTS";
import {retrievePromoStatus} from "_features/promotion/promotion-slice";

export const EntryCap = props => {

    logIt(GC.PAGE_ENTRY_CAP + " PAGE IS CALLED", null, false);
    const PAGE_DIV_NAME = "entry_cap"; // use underscores
    const PAGE_KEY = "entryCap"; // use camel case
    const PAGE_IMAGE_KEY = "entry-cap"; // use dashes
    const PAGE_JSON_KEY = "EntryCap"; // use init caps

    const dispatch = useDispatch();

    const promotion = useSelector(state => state.promotion);
    //logIt( "PROMOTION at " + GC.PAGE_TEMPLATE, promotion, true );

    const promoStatus = useSelector(state => state.promotion.promoStatus);
    //logIt( "PROMOTION at " + GC.PAGE_TEMPLATE, promotion, true );

    // LOCAL VARIABLES
    const [action, setAction] = useState('');
    const [value, setValue] = useState('');
    const [goNext, setGoNext] = useState(false);

    const doAction = (a, v) => {
        if (a.toUpperCase().trim() === GC.ACTION_NAVIGATE) {
            setAction(a);
            setValue(v);
            setGoNext(true);
        } else {
            dispatch(executeAction(a, v));
        }
    }

    useEffect(() => {
        if ( !empty(promotion) && empty(promoStatus) ) {
            dispatch( retrievePromoStatus(promotion?.configuration?.id) );
        }
    }, [promotion] );

    useEffect(() => {
        if ( !empty(promotion) && !empty(promoStatus) ) {
            if ( promoStatus.status === GC.STATUS_OK ) {
                setGoNext( true );
            } else if ( promoStatus.status === GC.STATUS_ERROR ) {
                let errMsg = parseErrorMessage( promoStatus );
                if ( errMsg === GC.STATUS_ERROR_MESSAGE_LIMIT_REACHED ) {
                    // do nothing, this is the entry cap page
                }
            }
        }
    }, [promoStatus] );

    if (goNext) {

        return (<PageFlow page={GC.PAGE_ENTRY_CAP} error={null} action={action} value={value}/>);

    } else {

        return (
            <React.Fragment>
                <TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title}/>
                <div id={PAGE_DIV_NAME + "_page"}>

                    <table>
                        <tbody>
                        <tr>
                            <td className="page-visual">

                                <PageBackgroundImages
                                    promotion={promotion}
                                    pageKey={PAGE_IMAGE_KEY}
                                />

                            </td>
                            <td className="page-content">

                                <div id={PAGE_DIV_NAME + "_content"}>

                                    <PageCopy
                                        promotion={promotion}
                                        configKey={PAGE_KEY}
                                        jsonKey={PAGE_JSON_KEY}
                                    />



                                    <ActionButtons
                                        promotion={promotion}
                                        configKey={PAGE_KEY}
                                        jsonKey={PAGE_JSON_KEY}
                                        doAction={doAction}
                                    />

                                </div>

                                <PromocracyLogo promotion={promotion}/>

                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <CustomContent/>

                </div>

            </React.Fragment>

        );

    }

}