import { createSlice } from '@reduxjs/toolkit';

import {logIt, empty, retrieveOriginalPath, getSlashSource} from '__globals/global-functions';
import {
	isPromocracyOn,
	retrievePromocracyDefaultDisplayName,
	retrievePromocracyParticipant,
	retrievePromocracyOverallSponsorAddOn, checkPromocracyVariation
} from '__globals/promocracy-functions'
import {getPromotion, getPromoJSON, getTriviaJSON, getPromocracyMapJSON, getPromoStatus} from '_api/PromotionAPI';
import {Navigate} from "react-router-dom";
import React from "react";

const promotionSlice = createSlice({
 name: 'promotion',
 initialState:{
	 retrieved:false,
	 configuration: {}
 },
 reducers: {
  processRemotePromotion(state, action) {
   if(action.payload !== undefined)
   {
	   let newVars = writePromotionVariables( action.payload.promotion );
	   logIt( "VARIABLES at processRemotePromotion", newVars, true );
	   state.variables = newVars;

	// logIt( "PROMOTION BEFORE PROCESS", state, true );
	state.configuration = action.payload.promotion;
	state.CONFIG = action.payload.promotion.config;
	state.retrieved = true;
   	//let strState = JSON.stringify( state );
   //	window.localStorage.promo = strState;
   }
  },
  processJSON( state, action ) {
	// logIt( "processJSON PROMO JSON", action.payload, false );
  	state.promoJSON = action.payload;
  },
	 processPromoStatus( state, action ) {
		 state.promoStatus = action.payload.promoStatus;
	 },
   processAmbassador(state, action) {
  	// logIt( "processAmbassador PAYLOAD", action.payload, true );
  	state.loggedOnAmbassador = action.payload;
  	//state.next = "entry";
  },
  processClearPromo(state, action) {
	  state = undefined; // promo dead
  },
  processLocalConfig(state, action) {
	  state.configuration.config = action.payload.config;
	  state.configuration.name = action.payload.name;
  },
  processSwitchLanguage(state, action) {
	  state.configuration.currentLanguage = action.payload.language;
	  state.configuration.promoCopy = action.payload.json;
	  state.configuration.languagePath = action.payload.languagePath;
  },
  processResetPromo( state, action ) {
	  state = action.payload;
  },
  processFatalError( state, action ) {
	  state.retrieved = true;
	  state.fatalError = true;
	  state.message = action.payload.message;
  }
 }
});


export const {
	processRemotePromotion,
	processJSON,
	processAmbassador,
	processClearPromo,
	processLocalConfig,
	processSwitchLanguage,
	processResetPromo,
	processFatalError,
	processPromoStatus
} = promotionSlice.actions;

export const fetchPromotion = ( domain, urlParams, props ) => async ( dispatch, getState ) => {

  if(!getState().promotion.retrieved)
  {
	logIt( "DOMAIN AT FETCH", domain, false );
    const promotion = await getPromotion(domain);
    logIt( "PROMOTION AT FETCH", promotion, true );
    if ( empty(promotion) ) {
    	let payload = {}
    	payload.message = "No promotion found for domain"
    	dispatch( processFatalError(payload) );
    } else {
	    promotion.currentLanguage = "en";
	    const pJSON = await getPromoJSON( promotion.name, promotion.currentLanguage );
		promotion.promoCopy = pJSON;
		if ( promotion.type?.value === "trivia" ||  promotion.config.variations?.trivia?.triviaOn) {
			const tJSON = await getTriviaJSON( promotion.name, promotion.currentLanguage );
			promotion.triviaCopy = tJSON;
		}
		if ( promotion.config.variations?.promocracy?.promocracyOn ) {
			const pmJSON = await getPromocracyMapJSON( promotion.name, promotion.currentLanguage );
			if ( !empty(pmJSON) ) {
				promotion.promocracyMap = "";
			}
		}
		promotion.urlParams = urlParams;
		if ( promotion.currentLanguage === "en" ) {
			promotion.languagePath = "";
		} else {
			promotion.languagePath = promotion.currentLanguage + "/";
		}
		// pass
		logIt( "PASS in fetchPromotion", urlParams.pass, false );
		if ('pass' in urlParams) {
			promotion.testPassword = urlParams.pass;
		}
		// res
		logIt( "RES in fetchPromotion", urlParams.res, false );
		if ('res' in urlParams) {
			promotion.testResult = urlParams.res;
		}
		// extans (for trivia)
		logIt( "EXTANS in fetchPromotion", urlParams.extans, false );
		if ('extans' in urlParams) {
			promotion.extans = urlParams.extans;
		}
		// tqid (for trivia)
		logIt( "TQID in fetchPromotion", urlParams.tqid, false );
		if ('tqid' in urlParams) {
			promotion.tqid = urlParams.tqid;
		}
		// uid
		logIt( "UID in fetchPromotion", urlParams.uid, false );
		if ('uid' in urlParams) {
			promotion.uid = urlParams.uid;
		} else {
			// if (!empty(promotion.config?.fieldsInUse?.noRegistration)) {
			// 	if (promotion.config.fieldsInUse.noRegistration === true) {
			// 		promotion.uid = "111111111111111";
			// 	}
			// }
		}
		// source
		let oPath = retrieveOriginalPath( props );
		if ('source' in urlParams) {
			promotion.source = urlParams.source;
			logIt( "SOURCE at fetchPromotion", urlParams.source, false );
		} else if ( !empty(oPath) ) {
			promotion.source = oPath;
			logIt( "SOURCE from Path OR param", oPath, false );
		} else {
			let slashSource = getSlashSource();
			logIt( "SLASH SOURCE", slashSource, false );
			if ( !empty(slashSource) ) {
				let promocracyParticipant = retrievePromocracyParticipant(promotion, slashSource);
				logIt( "PROMOCRACY PARTICIPANT at fetchPromotion", promocracyParticipant, true );
				if ( !empty(promocracyParticipant) ) {
					promotion.source = slashSource.toUpperCase();
				}
			}
		}

		// PREPARE PAYLOAD
		let payload = {};
		payload.promotion = promotion;
	    dispatch( processRemotePromotion(payload) );

    }
  }
}

export const clearThisPromo = () => async (dispatch, getState) => {
	  let payload = {};
	  dispatch( processClearPromo() );
}

export const retrievePromoStatus = ( promoId ) => async (dispatch, getState) => {
	const status = await getPromoStatus( promoId );
	let payload = {};
	payload.promoStatus = status;
	dispatch( processPromoStatus(payload) );
}

export const switchLanguage = ( promoName, language ) => async (dispatch, getState) => {
	const pJSON = await getPromoJSON( promoName, language );
	let payload = {};
	payload.language = language;
	payload.json = pJSON;
	if ( language === "en" ) {
		payload.languagePath = "";
	} else {
		payload.languagePath = language + "/";
	}
	dispatch( processSwitchLanguage(payload) );
}

{/** HELPER FUNCTIONS */}
function writePromotionVariables( promotionConfig ) {
	let newVars = {};
	newVars["promotionName"] = getPromoParamVal( promotionConfig, "name" );
	newVars["promotionStartDate"] = getPromoParamVal( promotionConfig, "startDate" );
	newVars["promotionEndDate"] = getPromoParamVal( promotionConfig, "endDate" );
	newVars["source"] = getPromoSourceParam( promotionConfig, "source" );
	newVars = checkPromocracyVariation( promotionConfig, newVars );
	return newVars;
}

function getPromoParamVal( promotionConfig, param ) {
	if ( !empty(promotionConfig) && !empty(promotionConfig[param]) ) {
		return promotionConfig[param];
	} else if ( !empty(promotionConfig) && !empty(promotionConfig.config[param]) ) {
		return promotionConfig.config[param];
	} else {
		return "NOT FOUND: " + param;
	}
}

function getPromoSourceParam( promotionConfig ) {
	let s = '';
	s = getPromoParamVal( promotionConfig, "source" );
	if ( s.startsWith("NOT FOUND:") ) {
		s = promotionConfig.source;
	}
	return s;
}


export default promotionSlice.reducer;



